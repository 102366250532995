import React, { useEffect, useState } from 'react';
import postsData from '../dummy-data/postsData.json';

export const BlogArea = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    console.log(postsData);
    setPosts(postsData);
  }, []);

  return (
    <div className="blog-area">
      <div className="container">
        <h2 className="sub-title">News</h2>
        <h2 className="heading-title">Some Pieces <br /> of the Newsletter</h2>
        <div className="ms-posts--wrap">
          <div className="row ms-posts--card" id="d681c37" data-order="">
            {posts.map((post) => (
              <article
                key={post.id}
                id={`post-${post.id}`}
                className={`grid-item col-sm-12 col-md-6 col-lg-4 post-${post.id} post type-post status-publish format-standard has-post-thumbnail hentry category-${post.category} tag-${post.tags.map(tag => tag).join(' tag-')}`}
              >
                <a href="#0" aria-label={post.title}>
                  <figure className="media-wrapper media-wrapper--4:3">
                    <img decoding="async" src={post.image} alt={post.title} />
                  </figure>
                </a>
                <div className="post-meta-cont">
                  <div className="post-header">
                    <div className="post-category">
                      <ul className="post-categories">
                        {post.categories.map((category, index) => (
                          <li key={index}>
                            <a href="#0" rel="category tag">{category}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <a href="#0" style={{textDecoration:'none'}} aria-label={post.title}>
                    <h3 >{post.title}</h3>
                  </a>
                  <p className="post-excerpt">{post.excerpt}</p>
                  <div className="post-meta-footer">
                    <div className="post-footer--author">
                      <img decoding="async" src={post.authorImage} alt={post.author} />
                      <div className="post-meta__info">
                        <span className="post-meta__author">{post.author}</span>
                        <div className="card__footer">
                          <span className="post-meta__date">{post.date}</span>
                          <span className="ms-p--ttr">{post.readTime} min read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


