import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import projectsData from "../dummy-data/projects.json";

const SingleProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectIndex = projectsData.findIndex((project) => project.id === id);
  const project = projectsData[projectIndex];

  if (!project) {
    return <p>Project not found</p>;
  }

  const handleNextProject = () => {
    const nextIndex = (projectIndex + 1) % projectsData.length; // Loop back to the first project if at the end
    const nextProject = projectsData[nextIndex];
    navigate(`/project/${nextProject.id}`);
  };

  return (
    <>
      <Header />
      <main className="ms-main single mt-5">
        <div className="ms-page-content">
          <div className="project-single-banner">
            <div className="container">
              <div className="project-single-inner">
                <div className="row top">
                  <div className="col-lg-6">
                    <h1 className="heading-title">{project.title}</h1>
                  </div>
                  <div className="col-lg-6">
                    <p className="desc">{project.description}</p>
                  </div>
                </div>
                <div className="row bottom">
                  <div className="col-lg-6">
                    <div className="author-area">
                      <div className="client">
                        <p className="tag">Client:</p>
                        <h4 className="platform">{project.client}</h4>
                      </div>
                      <div className="partner">
                        <p className="tag">Partners:</p>
                        <h4 className="platform">{project.partners}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <p className="category">
                      {Array.isArray(project.category)
                        ? project.category.join(" #")
                        : "No categories available"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="ms-hero project single">
            <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll"></div>
          </section>

          <section className="project-single-wrap">
            <div className="container">
              <div className="row">
                {project.images.map((image, index) => (
                  <div className="col-lg-6" key={index}>
                    <div className="portfolio-image">
                      <a href="#0">
                        <img src={image} alt={project.title} />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <div className="ms-spn--wrap container">
            <div className="ms-spn--content row">
              <div className="ms-spn--text col-md-6">
                <a
                  className="ms-spn--link"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNextProject();
                  }}
                >
                  <div className="ms-spn--head">
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z"></path>
                      </g>
                    </svg>
                    <h3>Next Project</h3>
                  </div>
                  <h1>{projectsData[(projectIndex + 1) % projectsData.length].title}</h1>
                </a>
              </div>
              <div className="col-md-6">
                <a href="#0" className="ms-spn--thumb">
                  <img
                    src="../assets/images/portfolio/03.jpg"
                    alt={projectsData[(projectIndex + 1) % projectsData.length].title}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SingleProject;
