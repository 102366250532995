/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BusinessJpeg from '../assets/images/business.jpeg';
import LogoSvg from '../assets/images/login_logo.svg';
import EyeSvg from '../assets/images/eye.svg';
import Backarrow from '../assets/images/backarrow.svg';

const LoginPage = () => {
  const [isSignUp, setIsSignUp] = useState(false); 
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false }); 
  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  return (
    <div className="row main">
      {/* Left side form */}
      <div className="col-md-6 bg-white p-0">
        <div className="row p-5">
          <div className="col-12 mb-4">
            <img src={Backarrow} className="text-2xl cursor-pointer" onClick={() => navigate(-1)} />
          </div>

          <div className="col-md-6 mb-3">
            <button
              onClick={() => setIsSignUp(false)}
              className={`buttons py-2 px-4 ${!isSignUp ? 'button-primary' : 'button-secondary'} `} >
              Sign In
            </button>
            </div>
            <div className="col-md-6 mb-3">
            <button
              onClick={() => setIsSignUp(true)}
              className= {`buttons py-2 px-4 ${isSignUp ? 'button-primary' : 'button-secondary'}`} >
              Sign Up
            </button>
          </div>
          <div className="col-12">
            {isSignUp ? (
              <div>
                <h2 className="text-lg font-bold mt-4">Register your account</h2>
                <div className="flex mt-4 ">
                  <button className="buttons flex-1 py-2 px-4 button-social rounded-l-lg flex items-center justify-center">
                    <span className="mr-1">Sign up with Gmail</span>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Gmail" />
                  </button>
                  <button className="buttons flex-1 py-2 px-4 button-social rounded-r-lg flex items-center justify-center">
                    <span className="mr-1">Sign up with Facebook</span>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook" />
                  </button>
                </div>

                <div class="col-12 text-center"><div class="line mt-4"></div> <span class="or"> Or </span></div>

                <form className="row mt-4 space-y-4">
                  <div className='col-6 text-center mt-3' >
                    <div className='inputbox m-auto'>
                      <input type="text" placeholder="First Name" className="hbox" />
                    </div>
                  </div>
                  <div className='col-6 text-center mt-3' >
                    <div className='inputbox m-auto'>
                      <input type="text" placeholder="Last Name" className="hbox" />
                    </div>
                  </div>     
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                      <input type="text" placeholder="School Key" className="hbox" />
                    </div>
                  </div>
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                      <input type="email" placeholder="Email Address" className="hbox" />
                    </div>
                  </div>
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                      <input type="text" placeholder="Username" className="hbox" />
                    </div>
                  </div>
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                      <input type={showPassword.password ? 'text' : 'password'}  placeholder="Password" className="hbox" />
                      <img src={EyeSvg} onClick={() => togglePasswordVisibility('password')} className="eye mt-3" />
                    </div>
                  </div>
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                    <input type={showPassword.confirmPassword ? 'text' : 'password'} placeholder="Confirm Password" className="hbox" />
                      <img src={EyeSvg} onClick={() => togglePasswordVisibility('confirmPassword')} className="eye mt-3" />
                    </div>
                    <p className="text-left mt-2 passcode">
                      The Password must be at least 6 characters, including symbols.
                    </p>
                  </div>
                  <div className='col-12 mb-5 mt-2'>
                    <button type="submit" className="btn buttons db bbutton">
                      <span>Sign Up</span>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                <h2 className="text-lg font-bold mt-4">Sign in to your account</h2>
                <form className="mt-4 space-y-4">
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                      <input type="text" placeholder="Username" className="hbox" />
                    </div>
                  </div>
                  <div className='col-12 text-center mt-3'>
                    <div className='inputbox m-auto'>
                      <input type={showPassword.password ? 'text' : 'password'} placeholder="Password" className="hbox" />
                      <img src={EyeSvg} onClick={() => togglePasswordVisibility('password')} className="eye mt-3" />
                    </div>
                  </div>
                  <div className='col-12 mb-5 mt-2'>
                    <button type="submit" className="btn buttons db bbutton">
                      <span>Sign In</span>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Right side image */}
      <div className="col-md-6 p-0 d-none d-md-block">
        <img src={BusinessJpeg} alt="Pro ERP Background" className="h-100 object-cover background-b" />
        <div className="PA h-100 text-center text-white img-text-wrapper d-flex flex-column justify-content-center align-items-center">
          <img src={LogoSvg} alt="Pro ERP Logo" className="mb-3 large-logo" />
          <p className="mb-1 text-large">PRO ERP</p>
          <div className="small-logo-text d-flex align-items-center">
            <img src={LogoSvg} alt="Small Logo" className="small-logo" />
            <div className="text-start ms-3">
              <p className="text-light fw-bold title">The Defcon Innovations product</p>
              <p className="text-light small"> Defcon Innovations Pvt. Ltd. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
