import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import projectsData from "../dummy-data/projects.json";

const ListStyle = () => {

  return (
    <>
      <Header />
      <main className="ms-main home-six list">
        <div className="banner-area-2">
          <div className="video-bg-container">
            <video autoPlay loop muted width="100%">
              <source src="assets/images/slider/Whisper.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="ms-tt-wrap">
            <ul className="ms-tt">
              {Array(8)
                .fill("")
                .map((_, index) => (
                  <li key={index} className="ms-tt__text">
                    Portfolio <span>List Style</span> by Most Theme Portfolio{" "}
                    <span>List Style</span> by Most Theme&nbsp;
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="ms-portfolio-filter-area main-isotop">
          <div className="container">
            <div className="button-group filters-button-group">
              <button className="button is-checked" data-filter="*">
                All Categories
              </button>
              <button className="button" data-filter=".creative">
                Creative
              </button>
              <button className="button" data-filter=".design">
                Design
              </button>
              <button className="button" data-filter=".photo">
                Photo
              </button>
              <button className="button" data-filter=".style">
                Style
              </button>
            </div>
            <div className="portfolio_wrap">
              <div className="filter row portfolio-feed">
                {projectsData.map((project, index) => (
                  <div
                    key={index}
                    className={`grid-item-p element-item transition ${project.category} col-md-12`}
                    data-category="transition"
                  >
                    <Link to={`/project/${project.id}`} aria-label={project.title}>
                      <div className="ms-p-list-item__img-container">
                        <figure className="project-list-item__image">
                          <img
                            decoding="async"
                            className="project-list-item__image"
                            src={project.image}
                            alt={project.title}
                          />
                        </figure>
                      </div>
                      <div className="p-list-item__title col-md-6">
                        <h3>{project.title}</h3>
                      </div>
                      <div className="p-list-item__info col-md-3">
                        <div className="p-list-item__cat">
                          <h4>{project.category}</h4>
                        </div>
                        <span>
                          <h4>/{project.year}</h4>
                        </span>
                      </div>
                      <div className="p-list-item__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384.67 384.67"
                        >
                          <g>
                            <path
                              fillRule="evenodd"
                              d="M0 45.26l276.05 276.05H22.63v63.36h362.04V22.63h-63.36v253.42L45.26 0 0 45.26z"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ListStyle;
