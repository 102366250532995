import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ContactPage from './pages/ContactUs';
import TeamPage from './pages/Team';
import GalleryPage from './pages/Gallery';
import Services from './pages/Services';
import ListStyle from './pages/ListStyle';
import Error from './pages/Error';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import GridStandard from './pages/GridStandrad';
import GridStyle1 from './pages/GridStyle1';
import ServiceDetail from './pages/ServiceDetail';
import LoginPage from './pages/LoginPage';
import About from './pages/About';
import SingleProject from './pages/SingleProject';


//JS files
// import "./assets/js/vendor/jquery.min.js"
// import "./assets/js/vendor/fslightbox.js"
// import "./assets/js/vendor/gsap.min.js"
// import "./assets/js/vendor/isotope.pkgd.min.js"
// import "./assets/js/vendor/imagesloaded.pkgd.min.js"
// import "./assets/js/vendor/jarallax-video.min.js"
// import "./assets/js/vendor/jarallax.min.js"
// import "./assets/js/vendor/jquery.justifiedGallery.min.js"
// import "./assets/js/vendor/modernizr.js"
// import "./assets/js/vendor/plyr.js"
// import "./assets/js/vendor/swiper-bundle.min.js"
// import "./assets/js/vendor/bootstrap.min.js"
// import "./assets/js/vendor/video-background.js"
// import "./assets/js/main.js"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<ContactPage/>} />
    <Route path="/team" element={<TeamPage/>} />
    <Route path="/gallery" element={<GalleryPage/>} />
    <Route path="/services" element={<Services/>} />
    <Route path="/liststyle" element={<ListStyle/>} />
    <Route path="/gridstandard" element={<GridStandard/>} />
    <Route path="/gridstyle1" element={<GridStyle1/>} />
    <Route path="/service/:id" element={<ServiceDetail/>} />
    <Route path="/project/:id" element={<SingleProject />} />
    <Route path="/login" element={<LoginPage/>} />
    <Route path="/error" element={<Error/>} />
  </Routes>
  </BrowserRouter>
);

reportWebVitals();
