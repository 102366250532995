import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import services from '../dummy-data/data.json';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const ServiceDetail = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    const foundService = services.find(service => service.id === parseInt(id));
    setService(foundService);
    console.log(foundService.images[1]);
  }, [id]);
  
  

  if (!service) {
    return <p>Loading...</p>;
  }

  return (
    <>
    <Header/>
    <div className="service-detail d-grid justify-center">
      <h1>{service.name}</h1>
      <p>{service.description}</p>
      <div className="images">
        {service.images.map((image, index) => (
          <img key={index} src={image} alt={`Service ${service.id}`} />
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default ServiceDetail;
