import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <>
        <footer className="ms-footer ms-footer--template">
        <section className="container footer-container" data-parallax="on">
            <div className="footer-title text-center">
                <h1>Got a <strong>PROJECT</strong> <br/> <strong>IN MIND?</strong> <Link to="/contact" className="btn-footer">Let's Talk <i className="fas fa-arrow-right"></i></Link></h1>
            </div>
            <div className="social-area">
                <div className="row area-inner">
                    <div className="col-lg-3 col-md-6 col-sm-2 col-2">
                        <div className="social-wrapper">
                            <div className="content">
                                <h3 className="platform">Dribble</h3>
                                <a href="#0" className="link">@madsparrow_dev</a>
                            </div>
                            <div className="social">
                                <a href="#0" className="icon"><FontAwesomeIcon icon={faFacebookF} size="2x" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-2 col-2">
                        <div className="social-wrapper">
                            <div className="content">
                                <h3 className="platform">Twitter</h3>
                                <a href="#0" className="link">@madsparrow_dev</a>
                            </div>
                            <div className="social">
                                <a href="#0" className="icon"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-2 col-2">
                        <div className="social-wrapper">
                            <div className="content">
                                <h3 className="platform">Instagram</h3>
                                <a href="#0" className="link">@madsparrow_dev</a>
                            </div>
                            <div className="social">
                                <a href="#0" className="icon"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-2 col-2">
                        <div className="social-wrapper">
                            <div className="content">
                                <h3 className="platform">Behance</h3>
                                <a href="#0" className="link">@madsparrow_dev</a>
                            </div>
                            <div className="social">
                                <a href="#0" className="icon"><FontAwesomeIcon icon={faLinkedinIn} size="2x" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="left-side">
                    <p>©2022 <a href="#0" className="author">Defcon Innovations</a>, All Rights Reserved.</p>
                </div>
            </div>
        </section>
    </footer>
    </>
  )
}
