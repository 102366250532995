import React, { useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'
import servicesData from '../dummy-data/data.json';

const Services = () => {
    const [services, setServices] = useState([]);

    useEffect(() => {
        // Load services from services.json or an API
        setServices(servicesData);
    }, []);
  return (
    <>
    <Header/>
    <main className="ms-main">
      <div className="ms-page-content">
        {/* Banner Area */}
        <section className="ms-hero services">
          <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
            <div className="ms-hc">
              <div className="ms-hc--inner">
                <h1 className="ms-hero-title">Services</h1>
                <p className="ms-hero-subtitle">Distinctively revolutionize<br /> unique deliverables.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Services Area */}
        <div className="services-area-2 services">
          <div className="container">
            <div className="services-area-inner">
              <div className="services-bottom">
                <div className="row">
                  {services.map((service, index) => (
                    <div key={index} className="col-lg-4 col-md-6 text-center">
                      <div className="ms-sb img-top">
                        <div className={`ms-sb--img ${service.className}`}>
                          <img src={service.image} className="attachment-full size-full" alt={service.title} />
                        </div>
                        <div className="ms-sb--inner">
                          <h3 className="ms-sb--title">
                            <span>{service.title}</span>
                          </h3>
                          <p className="ms-sb--text">{service.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer/>
    </>
  )
}

export default Services