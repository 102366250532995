import React from 'react';
import testimonialsData from "../dummy-data/testimonials.json";

const Testimonials = () => {
  return (
    <div className="testimonial-area">
      <div className="container">
        <h2 className="sub-title">Testimonials</h2>
        <h2 className="heading-title">Some Reviews From <br /> Clients About Us</h2>
        <div className="testimonial-item">
          <div className="row">
            {testimonialsData.map((testimonial, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12">
                <div className="testimonial-wraper">
                  <div className="content">{testimonial.content}</div>
                  <div className="author">
                    <div className="author-image">
                      <img src={testimonial.image} alt={testimonial.name} />
                    </div>
                    <div className="author-details">
                      <h2 className="name">{testimonial.name}</h2>
                      <p className="desc">{testimonial.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
