import React, { useEffect, useState } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import teamData from '../dummy-data/teamData.json';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const TeamPage = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    setTeam(teamData);
  }, []);

  return (
    <>
      <Header/>
      <main className="ms-main">
        <div className="ms-page-content">
          {/* Team Area One Start */}
          <div className="team-area one">
            <div className="container">
              <h4 className="heading-title">Team Widget</h4>
              <h1 className="title">Style #1</h1>
              <div className="team-area-inner">
                <div className="row">
                  {team.slice(0, 3).map((member) => (
                    <div className="col-lg-4" key={member.id}>
                      <div className={`ms-tm style-1 default d_row`}>
                        <div className="ms-tm--box">
                          <div className="ms-tm--img">
                            <img src={member.image} alt="Team Image" />
                            <div className="ms-tm--socials">
                              <a className="ms-s-icon ms-s-icon--s2" href={member.socials.facebook}>
                                <i aria-hidden="true" className="fab fa-facebook-f"></i>
                              </a>
                              <a className="ms-s-icon ms-s-icon--s2" href={member.socials.twitter}>
                                <i aria-hidden="true" className="fab fa-twitter"></i>
                              </a>
                              <a className="ms-s-icon ms-s-icon--s2" href={member.socials.linkedin}>
                                <i aria-hidden="true" className="fab fa-linkedin-in"></i>
                              </a>
                            </div>
                          </div>
                          <div className="ms-tm--content">
                            <h3 className="ms-tm--title">{member.name}</h3>
                            <p className="ms-tm--function">{member.role}</p>
                            <div className="ms-tm--desc">
                              <p></p>
                              <p>{member.description}</p>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Team Area Two */}
          <div className="team-area one two">
            <div className="container">
              <h4 className="heading-title">Team Widget</h4>
              <h1 className="title">Style #2</h1>
              <div className="team-area-inner two">
                <div className="row">
                  {team.slice(3, 6).map(member => (
                    <div key={member.id} className="col-lg-4">
                      <div className="ms-tm style-2 left">
                        <div className="ms-tm--box">
                          <div className="ms-tm--img">
                            <img src={member.image} alt={member.name} />
                            <div className="ms-tm--socials">
                              {Object.entries(member.socials).map(([key, url]) => (
                                <a key={key} className="ms-s-icon ms-s-icon--s2" href={url}>
                                  <i className={`fab fa-${key}`}></i>
                                </a>
                              ))}
                            </div>
                          </div>
                          <div className="ms-tm--content">
                            <h3 className="ms-tm--title">{member.name}</h3>
                            <p className="ms-tm--function">{member.role}</p>
                            <div className="ms-tm--desc">
                              <p>{member.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Team Area Three */}
          <div className="team-area one three">
            <div className="container">
              <h4 className="heading-title">Team Widget</h4>
              <h1 className="title">Style #3</h1>
              <div className="team-area-inner two">
                <div className="row">
                  {team.slice(3, 6).map(member => (
                    <div key={member.id} className="col-lg-4">
                      <div className="ms-tm style-3 default d_row">
                        <div className="ms-tm--box">
                          <div className="ms-tm--img">
                            <img src={member.image} alt={member.name} />
                            <div className="ms-tm--socials">
                              {Object.entries(member.socials).map(([key, url]) => (
                                <a key={key} className="ms-s-icon ms-s-icon--s2" href={url}>
                                  <i className={`fab fa-${key}`}></i>
                                </a>
                              ))}
                            </div>
                          </div>
                          <div className="ms-tm--content">
                            <h3 className="ms-tm--title">{member.name}</h3>
                            <p className="ms-tm--function">{member.role}</p>
                            <div className="ms-tm--desc">
                              <p>{member.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
};

export default TeamPage;
