/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import pricingData from '../dummy-data/pricingData.json'; 

const PricingArea = () => {
    return (
        <div className="pricing-area-2">
            <div className="container">
                <h2 className="sub-title">Pricing</h2>
                <h2 className="heading-title">You Can See <br /> Our Loyal Prices</h2>
                <div className="pricing-inner">
                    <div className="row">
                        {pricingData.plans.map((plan) => (
                            <div className="col-lg-4 col-md-6" key={plan.id}>
                                <div className="ms-pt-block">
                                    <div className="ms-pt--price"></div>
                                    <div className="ms-pt--header top">
                                        <h4 className="ms-pt--title">{plan.title}</h4>
                                        <span className="currency currency--before">$</span>
                                        <span className="price">{plan.price}</span>
                                        <span className="period">/ Month</span>
                                    </div>
                                    <div className="ms-pt--content">
                                        <ul>
                                            {plan.features.map((feature, index) => (
                                                <li className={feature.active ? 'active' : 'no-active'} key={index}>
                                                    <i className="icon-check">
                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M20 6L9 17l-5-5"></path>
                                                        </svg>
                                                    </i>
                                                    <span>{feature.text}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="ms-pt--footer">
                                        <a className="btn btn--md btn--primary btn--full-width" role="button" href="#">
                                            <div className="ms-btn__text">
                                                <span className="text--main">Sign Up</span>
                                            </div>
                                            <span className="ms-btn--ripple"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PricingArea;
