import React, { useState, useEffect } from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import galleryData from '../dummy-data/galleryData.json';

const GalleryPage = () => {
  const [gallery1, setGallery1] = useState([]);
  const [gallery2, setGallery2] = useState([]);
  const [gallery3, setGallery3] = useState([]);

  useEffect(() => {
    setGallery1(galleryData.gallery1);
    setGallery2(galleryData.gallery2);
    setGallery3(galleryData.gallery3);
  }, []);

  return (
    <>
    <Header/>
    <main className="ms-main">
      <div className="ms-page-content">
        {/* Gallery Area One */}
        <div className="team-area one gallery">
          <div className="container">
            <h4 className="heading-title">Gallery Widget</h4>
            <h1 className="title">Simple Grid 3 col. <br /> Hover Style #1</h1>
            <div className="ms-content--portfolio">
              <div className="row grid grid-content blockgallery h_s1">
                <div className="grid-sizer col-xs-12 col-lg-4 col-md-3"></div>
                {gallery1.map((item, index) => (
                  <div key={index} className="grid-item col-6 col-lg-4 col-md-4">
                    <a className="mfp-img" href="#0" title="">
                      <figure className="media-wrapper media-wrapper--4:3">
                        <img decoding="async" src={item.src} alt={item.alt} />
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Gallery Area Two */}
        <div className="team-area one two gallery gallery1">
          <div className="container">
            <h4 className="heading-title">Gallery Widget</h4>
            <h1 className="title">Justified <br /> Hover Style #2</h1>
            <div className="ms-content--portfolio">
              <div className="row grid grid-content blockgallery">
                <div className="grid-sizer col-xs-12 col-lg-4 col-md-3"></div>
                {gallery2.map((item, index) => (
                  <div key={index} className={`grid-item col-6 col-lg-4 col-md-4 ${index % 2 === 0 ? 'one' : ''}`}>
                    <a className="mfp-img" href="#0" title="">
                      <figure className={`media-wrapper ${index % 2 === 0 ? 'one' : ''} media-wrapper--4:3`}>
                        <img decoding="async" src={item.src} alt={item.alt} />
                      </figure>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Gallery Area Three */}
        <div className="ms-portfolio-filter-area project gallery">
          <div className="container">
            <h4 className="heading-title">Gallery Widget</h4>
            <h1 className="title">Masonry <br /> Hover Style #3</h1>
            <div className="portfolio_wrap">
              <div className="filter grid-masonary row ms-masonry-gallery portfolio-feed">
                {gallery3.map((item, index) => (
                  <div key={index} className={`fadein zoom center h-align-middle grid-item-p element-item ${item.category} col-md-4`} data-category={item.category}>
                    <div className="item--inner">
                      <a href="#0" aria-label="Freezing Birthday">
                        <figure className="ms-p-img">
                          <img decoding="async" src={item.src} alt={item.alt} />
                        </figure>
                        <div className="ms-p-content">
                          <div className="mfp-icon">
                            <i aria-hidden="true" className="fas fa-eye"></i>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer/>
    </>
  );
};

export default GalleryPage;
