import React from 'react';
import projectsData from '../dummy-data/projectarea.json';

const ProjectsArea = () => {
  return (
    <>
    <div className="services-area home-six">
      <div className="services-bottom">
        <div className="container">
          <h2 className="sub-title">Projects</h2>
          <h2 className="heading-title">Some of the Projects <br /> We Have Done Well</h2>
          <div className="project-item">
            <div className="row">
              {projectsData.map((project, index) => (
                <div key={index} className="col-lg-6">
                  <div className="portfolio_wrap" id={project.id}>
                    <div className="portfolio-feed ms-p--d row">
                      <span className="load_filter">
                        <svg
                          className="load-filter-icon"
                          width="100%"
                          height="100%"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <circle
                            cx="50"
                            cy="50"
                            r="30"
                            strokeWidth="6"
                            strokeLinecap="round"
                            fill="none"
                          >
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              repeatCount="indefinite"
                              dur="1s"
                              values="0 50 50;180 50 50;720 50 50"
                              keyTimes="0;0.5;1"
                            />
                            <animate
                              attributeName="stroke-dasharray"
                              repeatCount="indefinite"
                              dur="1s"
                              values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
                              keyTimes="0;0.5;1"
                            />
                          </circle>
                        </svg>
                      </span>
                      <div className="grid-sizer col-md-12"></div>
                      {project.items.map((item, idx) => (
                        <div
                          key={idx}
                          className={`below zoom left grid-item-p col-md-6 ${item.className}`}
                        >
                          <div className="item--inner">
                            <a href={item.link} aria-label={item.title}>
                              <figure className={`ms-p-img media-wrapper media-wrapper--${item.aspectRatio}`}>
                                <img
                                  decoding="async"
                                  src={item.image}
                                  alt={item.title}
                                />
                              </figure>
                              <div className="ms-p-content">
                                <h3>{item.title}</h3>
                                <h4 className="ms-p-cat">{item.category}</h4>
                              </div>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProjectsArea;
