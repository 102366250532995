import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logoLight from '../assets/images/logo/favicon.png';
import logoDark from '../assets/images/logo/favicon.png';
import services from '../dummy-data/data.json';

export const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);


  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Toggle theme mode
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.style.backgroundColor = isDarkMode ? 'white' : 'black';
    document.body.style.color = isDarkMode ? 'black' : 'white';

    // Add or remove dark-mode class from body
    if (isDarkMode) {
      document.body.classList.remove('dark-mode');
    } else {
      document.body.classList.add('dark-mode');
    }
  };


  // Toggle search popup
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <>
      <div className={`main-header js-main-header auto-hide-header full-width menu-center header--sticky ${isDarkMode ? 'dark-mode' : ''}`} data-blur="on">
        <div className="main-header__layout">
          <div className="container">
            <div className="main-header__inner p-none">
              <div className="main-header__logo">
                <div className="logo-dark">
                  <Link to="index">
                    <img src={logoLight} alt="Most" />
                  </Link>
                </div>
                <div className="logo-light">
                  <Link to="index">
                    <img src={logoDark} alt="Most" />
                  </Link>
                </div>
              </div>

              <nav className="main-header__nav js-main-header__nav main-header__default" id="main-header-nav" aria-labelledby="primary-menu">
                <ul id="primary-menu" className="navbar-nav">
                  <li className="menu-item menu-item-has-children">
                    <Link to="/" title="Digital Transformation"><span>Digital Transformation</span></Link>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="#" title="Industries"><span>Industries</span></Link>
                    <ul className="sub-menu">
                      <li id="menu-item-170" className="menu-item"><Link to="blog-right-sidebar">Blog Right Sidebar</Link></li>
                      <li id="menu-item-166" className="menu-item"><Link to="blog-card">Blog Card</Link></li>
                      <li id="menu-item-169" className="menu-item"><Link to="blog-list-left">Blog List Left Thumb</Link></li>
                      <li id="menu-item-167" className="menu-item"><Link to="blog-list-random">Blog List Random Thumb</Link></li>
                      <li id="menu-item-168" className="menu-item"><Link to="blog-list-right">Blog List Right Thumb</Link></li>
                      <li id="menu-item-168" className="menu-item"><Link to="blog-single">Blog Single</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="#" title="Solution"><span>Solution</span></Link>
                    <ul className="sub-menu">
                      <li id="menu-item-75" className="menu-item"><Link to="/liststyle">List Style</Link></li>
                      <li id="menu-item-171" className="menu-item"><Link to="/gridstandard">Grid Standard</Link></li>
                      <li id="menu-item-261" className="menu-item"><Link to="/gridstyle1">Grid Style 1</Link></li>
                      <li id="menu-item-240" className="menu-item"><Link to="project-style-2">Grid Style 2</Link></li>
                      <li id="menu-item-459" className="menu-item"><Link to="project-masonary">Grid Masonry</Link></li>
                      <li id="menu-item-459" className="menu-item"><Link to="project-single">Project Single</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="/services" title="Apps"><span>Apps</span></Link>
                    <ul className="sub-menu">
                      {services.map(service => (
                        <li key={service.id} className="menu-item">
                          <Link to={`/service/${service.id}`} title={service.name}>
                            {service.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="menu-item">
                    <Link to="/contact" title="Customer Stories"><span>Insights</span></Link>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link title="About Us"><span>About Us</span></Link>
                    <ul className="sub-menu">
                      <li id="menu-item-1988" className="menu-item"><Link to="/about">About Us</Link></li>
                      <li id="menu-item-2249" className="menu-item"><Link to="/team">Team Page</Link></li>
                      <li id="menu-item-2363" className="menu-item"><Link to="/gallery">Image Gallery</Link></li>
                      <li id="menu-item-2421" className="menu-item"><Link to="/services">Services</Link></li>
                      <li id="menu-item-1961" className="menu-item"><Link to="/error">Page 404</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link to="/login" title="Login"><span>Login</span></Link>
                  </li>
                </ul>
              </nav>
              <div className="menuTrigger"></div>
              <div className="main-header--widgets">
                <div className="ms_theme_mode">
                  <div className="ms_tm--inner">
                    <div className="theme-toggle" id="theme-toggle" onClick={toggleTheme}>
                      <input type="checkbox" id="switcher" className="check" checked={isDarkMode} onChange={toggleTheme} />
                      <svg className="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
                        <mask className="moon" id="moon-mask">
                          <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
                          <circle cx="24" cy="10" r="6" fill="black"></circle>
                        </mask>
                        <circle className="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)" fill="currentColor"></circle>
                        <g className="sun-beams" stroke="currentColor">
                          <line x1="12" y1="1" x2="12" y2="3"></line>
                          <line x1="12" y1="21" x2="12" y2="23"></line>
                          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                          <line x1="1" y1="12" x2="3" y2="12"></line>
                          <line x1="21" y1="12" x2="23" y2="12"></line>
                          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="header__search-icon" onClick={toggleSearch}>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.57,16.15A9,9,0,1,0,15,17.46h0l6.25,6.25,1.42-1.42Zm-3-.14a7.07,7.07,0,1,1,1.56-1.28A6.88,6.88,0,0,1,13.59,16Z"></path>
                  </svg>
                </div>
                {isSearchOpen && (
                  <div className="header__search-popup">
                    <form method="get" role="search" className="search-form">
                      <input type="search" className="search-field" placeholder="Search" />
                      <button type="submit" className="search-submit">
                        SUBMIT
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.57,16.15A9,9,0,1,0,15,17.46h0l6.25,6.25,1.42-1.42Zm-3-.14a7.07,7.07,0,1,1,1.56-1.28A6.88,6.88,0,0,1,13.59,16Z"></path>
                        </svg>
                      </button>
                      <div className="header__search-close" onClick={toggleSearch}>
                        CLOSE
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.41L12,10.59,5.71,4.3a1,1,0,0,0-1.42,1.42L10.59,12,4.3,18.3a1,1,0,0,0,1.42,1.42L12,13.41,18.3,19.7a1,1,0,0,0,1.42-1.42Z"></path>
                        </svg>
                      </div>
                    </form>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`

        .main-header {
          position: sticky;
          top: 0;
          z-index: 100;
          background-color: inherit;
        }

        .header__search-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .header__search-close {
          position: absolute;
          top: -10px;
          right: 4px;
          cursor: pointer;
          height: 20px;
          width: 20px;
        }

        .search-form {
          position: relative;
          width: 80%;
          max-width: 600px;
        }

        .search-field {
          width: 100%;
          padding: 10px;
          border-radius: 4px;
          background-color: ${isDarkMode ? '#333' : '#fff'};
          border: none;
          color: ${isDarkMode ? 'white' : 'black'};
        }

        .search-submit {
          position: absolute;
          /* height: 30px; */
          width: 55px;
          right: 25px;
          top: 10px;
          transform: translateY(-50%);
          background: none;
          border: none;
          cursor: pointer;
        }

        .header__search-popup {
          opacity: ${isSearchOpen ? '1' : '0'};
          visibility: ${isSearchOpen ? 'visible' : 'hidden'};
          transition: opacity 0.3s, visibility 0.3s;
        }

      `}</style>
    </>
  )
}
