import React from 'react';
import servicesData from '../dummy-data/data.json';

const ServicesArea = () => {
  return (
    <>
    <div className="services-area home-six">
      <div className="container">
        <h2 className="sub-title">Services</h2>
        <h2 className="heading-title">The Service <br /> We Provide For You</h2>
        <div className="services-item">
          <div className="row">
            {servicesData.slice(0, 6).map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12">
                <div className="ms-sb img-top">
                  <div className={`ms-sb--img boxed ${service.className}`}>
                    <img
                      src={service.image}
                      className="attachment-full size-full"
                      alt={service.title}
                    />
                  </div>
                  <div className="ms-sb--inner">
                    <h3 className="ms-sb--title">
                      <span>{service.title}</span>
                    </h3>
                    <p className="ms-sb--text">{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ServicesArea;
