import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const ContactPage = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
    <Header/>
    <main className="ms-main">
      <div className="ms-page-content">
        <div className="contact-map-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="contact-map-area-fluid">
                  <iframe
                    className="contact-map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14602.288851207937!2d90.47855065!3d23.798243149999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1663151706353!5m2!1sen!2sbd"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Map"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-area contact">
          <div className="container">
            <div className="contact-area-inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <h2 className="title">
                      Got A Project Or <br /> A Partnership In Mind?
                    </h2>
                    <p className="desc">
                      Holisticly leverage other’s user-friendly platforms with
                      progressive products. Proactively matrix exceptional
                      content through B2C schemas. Seamlessly exploit
                      cutting-edge niche markets rather than premium results.
                      Collaboratively restore pandemic e-business and
                      plug-and-play data. Conveniently target exceptional
                      platforms whereas standards-compliant data.
                    </p>
                  </div>
                  <div className="row contact">
                    <ul className="col-lg-6 phone">
                      <li className="tag">Phone:</li>
                      <li>+99 (0)1047011888</li>
                      <li>+99 (0)1310011444</li>
                    </ul>
                    <ul className="col-lg-6 email">
                      <li className="tag">Email:</li>
                      <li>info@madsparrow.com</li>
                      <li>contact@most.com</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-form">
                    <form
                      onSubmit={handleSubmit}
                      aria-label="Contact form"
                    >
                      <div className="row">
                        <div className="form-group col-6">
                          <input
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Name"
                            type="text"
                            name="your-name"
                            required
                          />
                        </div>
                        <div className="form-group col-6">
                          <input
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Email"
                            type="email"
                            name="your-email"
                            required
                          />
                        </div>
                        <div className="form-group col-12">
                          <input
                            aria-invalid="false"
                            placeholder="Title"
                            type="text"
                            name="your-subject"
                            required
                          />
                        </div>
                        <div className="form-group col-12">
                          <textarea
                            aria-invalid="false"
                            placeholder="Message"
                            name="message"
                          ></textarea>
                        </div>
                        <div className="ms-cf--bottom">
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="Send"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer/>
    </>
  );
};

export default ContactPage;
