import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import aboutImage from '../assets/images/about/about-us.png';
import team01 from '../assets/images/team/team-01.jpg';
import team02 from '../assets/images/team/team-02.jpg';
import team03 from '../assets/images/team/team-03.jpg';
import team04 from '../assets/images/team/team-04.jpg';
import team05 from '../assets/images/team/team-05.jpg';
import team06 from '../assets/images/team/team-06.jpg';

const About = () => {
  return (
    <>
      <Header />

      <main className="ms-main">
        <div className="ms-page-content">
          {/* Banner Area Start */}
          <div className="banner-area inner">
            <div className="container">
              <h1 className="heading-title">
                We A Graphic <strong><span>Design Agency</span></strong> <br /> For visually Compelling Stories.
              </h1>
            </div>
          </div>
          {/* Banner Area End */}

          {/* Hero Area Start */}
          <div className="ms-hero-area-2">
            <div className="row">
              <div className="col-12">
                <div className="hero-image">
                  <img src={aboutImage} alt="About Us" />
                </div>
              </div>
            </div>
          </div>
          {/* Hero Area End */}

          {/* Team Rules Area Start */}
          <div className="team-rules-area">
            <div className="container">
              <div className="team-rules-area-inner">
                <div className="border-line"></div>
                <div className="top">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <h4 className="heading-title">Our Team Rules</h4>
                    </div>
                    <div className="col-lg-8 col-md-8">
                      <h1 className="title">
                        Authoritatively seize web readiness. Completely benchmark partnerships.
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="middle">
                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4 col-md-6">
                      <div className="inner">
                        <div className="border-line"></div>
                        <div className="content">
                          <p className="desc">Team Rule <br /> (No.01)</p>
                          <h2 className="sub-title">Love what we do</h2>
                          <p>
                            Completely plagiarize intermandated services whereas multifunctional mindshare. Monotonectally mesh low-risk high-yield methods of empowerment after cross functional testing procedures.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="inner">
                        <div className="border-line"></div>
                        <div className="content">
                          <p className="desc">Team Rule <br /> (No.02)</p>
                          <h2 className="sub-title">Trust</h2>
                          <p>
                            Dynamically maintain reliable e-services without prospective supply chains. Continually deploy cross-unit niches via seamless networks.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="inner">
                        <div className="border-line"></div>
                        <div className="content">
                          <p className="desc">Team Rule <br /> (No.03)</p>
                          <h2 className="sub-title">Communication</h2>
                          <p>
                            Appropriately parallel task cutting-edge mindshare rather than B2B catalysts for change. Efficiently myocardinate collaborative niche markets without excellent web-readiness.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="inner">
                        <div className="border-line"></div>
                        <div className="content">
                          <p className="desc">Team Rule <br /> (No.04)</p>
                          <h2 className="sub-title">Honesty</h2>
                          <p>
                            Monotonectally parallel task cross-unit e-tailers without performance based platforms.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team Rules Area End */}

          {/* Team Area Start */}
          <div className="team-area">
            <div className="container">
              <div className="team-area-inner">
                <div className="border-line"></div>
                <div className="team-content">
                  <h4 className="heading-title">Our Team</h4>
                  <h1 className="title">We Have Come <br /> Together to Create</h1>
                </div>
                <div className="team-member">
                  <div className="row">
                    {[team01, team02, team03, team04, team05, team06].map((teamImage, index) => (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <div className="ms-tm style-2 left ">
                          <div className="ms-tm--box">
                            <div className="ms-tm--img">
                              <img src={teamImage} alt="Team Member" />
                              <div className="ms-tm--socials">
                                <a className="ms-s-icon ms-s-icon--s2" href="#0">
                                  <i aria-hidden="true" className="fab fa-facebook-f"></i>
                                </a>
                                <a className="ms-s-icon ms-s-icon--s2" href="#0">
                                  <i aria-hidden="true" className="fab fa-twitter"></i>
                                </a>
                                <a className="ms-s-icon ms-s-icon--s2" href="#0">
                                  <i aria-hidden="true" className="fab fa-linkedin-in"></i>
                                </a>
                              </div>
                            </div>
                            <div className="ms-tm--content">
                              <h3 className="ms-tm--title">Team Member Name</h3>
                              <p className="ms-tm--function">Position</p>
                              <div className="ms-tm--desc">
                                <p>Rapidiously synergize revolutionary portals with holistic leadership skills.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team Area End */}
        </div>
      </main>

      <Footer />
    </>
  );
};

export default About;
