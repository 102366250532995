import "./App.css";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import {BlogArea} from "./components/BlogArea";
import Testimonials from "./pages/Testimonials";
import ServicesArea from "./components/ServicesArea";
import ProjectsArea from "./components/ProjectsArea";
import PricingArea from "./components/PricingArea";
import DemoReel from "./assets/images/slider/Demo-Reel.mp4";

function App() {
  return (
    <>
      <Header />
      <main className="ms-main home-six">
      <div className="banner-area-2">
      <div className="video-bg-container">
        <video autoPlay loop muted width="10000">
          <source src="http://defconinnovations.com/defconbackwall.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="ms-tt-wrap">
        <ul className="ms-tt">
          {[...Array(8)].map((_, index) => (
            <li key={index} className="ms-tt__text">
               Boot Your business with the power of AI <span>•</span>  Boot Your business with the power of AI <span>•</span>&nbsp;
            </li>
          ))}
        </ul>
      </div>
    </div>
    <ServicesArea/>
    <ProjectsArea/>
    <Testimonials/>
    <BlogArea/>
    <PricingArea/>
      </main>
      <Footer />
    </>
  );
}

export default App;
